<script>
import adminProfileStructure from '@components/adminProfileStructure'
import moment from "moment";
import {mapState} from "vuex";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";

export default {
  page: {
      title: 'System Coupons - Admin',
  },
  components: {PaginationRow, adminProfileStructure},
  mixins: [PaginatedDataMixin],
  computed:{
      ...mapState({
        user: state => state.auth.currentUser
      })
  },
  data() {
    return {
      visible_codes: [],
      fields: [
        {key: 'code', label: 'Κωδικός κουπονιού'},
        {key: 'discountType', label: 'Τύπος έκπτωσης', formatter: val => {
          if(val === 0) return '💲️ Τιμή πώλησης (π.χ. 1240€)'
            return '✂️ Έκπτωση (π.χ. -100€)'
          }},
        {key: 'amount', label: 'Τιμή αγοράς', formatter: val => val + '€'},
        {key: 'access_token', label: 'Κωδικός συνεργάτη', formatter: val => '*********'},
      ],
      discountTypeOptions: [
        {value: 0, text: '💲️ Τιμή πώλησης (π.χ. 1240€)'},
        {value: 2, text: '✂️ Έκπτωση (π.χ. -100€)'},
      ],
      showCouponCode: false,
      coupon: {
        id: null,
        code: null,
        access_token: null,
        discountType: 0,
        amount: null
      }
    }
  },
  created: function(){
    this.api.url = "/admin/coupons";
    this.getData();
  },
  methods:{
    createCouponModal(){
      this.coupon = {
        id: null,
        code: null,
        access_token: null,
        discountType: 0,
        amount: null
      }
      this.$refs['create-edit-coupon'].show()
    },
    toggleEditCoupon(coupon){
      this.coupon = coupon;
      this.$refs['create-edit-coupon'].show();
    },
    hideCouponModal(){
      this.$refs['create-edit-coupon'].hide();
    },
    updateCoupon(){
      this.$axios.put('/admin/coupons/'+this.coupon.id, this.coupon).then(res=>{

        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: this.$t('success.text')});
        this.hideCouponModal();
        this.getData();
      }).catch(e=>{
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
      })
    },
    createCoupon(){
      this.$axios.post('/admin/coupons', this.coupon).then(res=>{

        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: this.$t('success.text')});
        this.hideCouponModal();
        this.getData();
      }).catch(e=>{
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
      })
    }
  }
}
</script>
<template>
<adminProfileStructure :user="user">
  <template v-slot:admin-structure-content>

    <div id="coupons">
      <b-card-title>

        <div class="row">
          <div class="col-6">
            Κουπόνια Προσφοράς / Συνεργατών
          </div>
          <div class="col-6 text-right">
            <b-button variant="outline-primary" @click="createCouponModal">Δημιουργία νέου</b-button>

          </div>
        </div>

      </b-card-title>


      <div v-if="api.rows.length > 0" class="table-responsive table-borderless table-striped mb-0">
        <b-table :items="api.rows" :fields="fields" class="table table-hover cursor-pointer" responsive="sm" :current-page="api.page"
                 @row-clicked="toggleEditCoupon">
        </b-table>
      </div>

      <pagination-row :api="api" @fetchData="getData"></pagination-row>


      <b-modal ref="create-edit-coupon" :title="`${coupon.id ? 'Επεξεργασία' : 'Δημιουργία'} Κουπονιού`" hide-footer>


        <b-form autocomplete="off">
          <b-form-group label="Κωδ. Κουπονιού">
            <b-form-input v-model="coupon.code" @input="val => coupon.code = val.toUpperCase()" :disabled="coupon.id"></b-form-input>
          </b-form-group>
          <b-form-group label="Είδος έκπτωσης">
            <b-form-select id="text-only-select" v-model="coupon.discountType" :options="discountTypeOptions"></b-form-select>
          </b-form-group>
          <b-form-group label="Ποσό (σταθερό ή έκπτωσης)">
            <b-form-input v-model="coupon.amount" type="number" name="amount" autocomplete="off"></b-form-input>
          </b-form-group>

          <b-form-group label="Κωδικός πρόσβασης συνεργάτη (συστήνεται η δημιουργία του)">
            <b-button variant="link" @click="showCouponCode = !showCouponCode">{{ showCouponCode ? 'Απόκρυψη' : 'Εμφάνιση' }}</b-button>
            <b-form-input v-model="coupon.access_token" :type="showCouponCode ? 'text' :'password'" autocomplete="off"></b-form-input>
          </b-form-group>


          <div class="mt-2 text-right">


            <save-button v-if="coupon.id" @click="updateCoupon" :disabled="!coupon.amount"></save-button>
            <b-button v-else variant="primary" @click="createCoupon" :disabled="!coupon.amount || !coupon.code">Δημιουργία</b-button>
          </div>
        </b-form>

      </b-modal>
    </div>

  </template>

</adminProfileStructure>
</template>

<style lang="scss" >
#coupons tr{
  cursor:pointer;
}
</style>